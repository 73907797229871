
import {
  VehicleWithdrawalList,
  VehicleWithdrawalListVehicle,
} from '@/views/VehicleReportPrint.vue'
import { format } from 'date-fns'
import QTable from 'quasar/src/components/table/QTable.js';
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'VehicleReportPrintCanceledTable',

  props: {
    list: {
      type: Array as PropType<VehicleWithdrawalList[]>,
      required: true,
    },
  },

  setup() {
    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: 'vehicle_name',
        label: 'Fordon',
        field: (x: VehicleWithdrawalListVehicle) => x.vehicle.name,
        sortable: false,
      },
      {
        align: 'left',
        name: 'withdrawn_place_name_short',
        label: 'Avställd plats',
        style: 'white-space: pre-line',
        field: (x: VehicleWithdrawalListVehicle) => x.vehicleWithdrawalDepot,
        sortable: false,
      },
      {
        align: 'left',
        name: 'prognosis',
        label: 'Prognos',
        field: (x: VehicleWithdrawalListVehicle) => x.prognosis,
        format: (x: string | null) =>
          x ? format(new Date(x), 'yyyy-MM-dd HH:mm') : '',
        sortable: false,
      },
      {
        align: 'left',
        name: 'withdrawn_reason',
        label: 'Orsak till att inte tillgängliga',
        field: (x: VehicleWithdrawalListVehicle) => x.vehicleWithdrawalReason,
        sortable: false,
      },
      {
        align: 'left',
        name: 'comment',
        label: 'Kommentar',
        field: (x: VehicleWithdrawalListVehicle) => x.comment,
        style: 'white-space: normal',
        sortable: false,
      },
    ]

    return {
      columns,
    }
  },
})
