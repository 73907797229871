import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createBlock(_component_q_table, {
    class: "q-mt-xl",
    bordered: "",
    flat: "",
    rows: _ctx.data,
    columns: _ctx.columns,
    "hide-bottom": "",
    separator: "cell",
    style: {"width":"430px"},
    "rows-per-page-options": [0],
    dense: ""
  }, {
    header: _withCtx((props) => [
      _createVNode(_component_q_tr, {
        props: props,
        style: { textAlign: 'left' },
        class: "bg-grey-3"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
            return (_openBlock(), _createBlock(_component_q_th, {
              key: col.name,
              props: props
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(col.label), 1)
              ]),
              _: 2
            }, 1032, ["props"]))
          }), 128))
        ]),
        _: 2
      }, 1032, ["props"])
    ]),
    body: _withCtx((props) => [
      _createVNode(_component_q_tr, { props: props }, {
        default: _withCtx(() => [
          _createVNode(_component_q_td, {
            key: "text",
            props: props
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.row.text), 1)
            ]),
            _: 2
          }, 1032, ["props"]),
          _createVNode(_component_q_td, {
            key: "x10p",
            props: props
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.row.x10p), 1)
            ]),
            _: 2
          }, 1032, ["props"]),
          _createVNode(_component_q_td, {
            key: "x15p",
            props: props
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.row.x15p), 1)
            ]),
            _: 2
          }, 1032, ["props"]),
          _createVNode(_component_q_td, {
            key: "total",
            props: props
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.row.total), 1)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 2
      }, 1032, ["props"])
    ]),
    _: 1
  }, 8, ["rows", "columns"]))
}