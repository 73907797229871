import { alphaClient } from '@/services/api-client'

export const getFordonEnligtUtsattningsplan = (
  date: string
): Promise<{ count: number; x10p: number; x15p: number }> =>
  alphaClient
    .get<{ count: number; x10p: number; x15p: number }>(
      `/custom/transdev-lokalbanor/fordon-enligt-utsattningsplan?date=${date}`
    )
    .then(({ data }) => {
      if (!data) {
        return {
          count: 0,
          x10p: 0,
          x15p: 0,
        }
      }

      return data
    })
