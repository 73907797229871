
import { defineComponent, computed, ref } from 'vue'
import VehicleReportPrintAvailability from '@/components/vehicle-report-print/VehicleReportPrintAvailability.vue'
import VehicleReportPrintCanceledTable from '@/components/vehicle-report-print/VehicleReportPrintCanceledTable.vue'
import AppLogo from '@/components/AppLogo.vue'
import { useVehicleWithdrawal } from '@/compositions/useVehicleWithdrawal'
import { useVehicle } from '@/compositions/useVehicle'
import { VehicleWithdrawal } from '@/types/VehicleWithdrawal'
import { VehicleComposition } from '@/types/VehicleComposition'
import { VehicleWithdrawalReason } from '@/types/VehicleWithdrawalReason'
import { VehicleWithdrawalDepot } from '@/types/VehicleWithdrawalDepot'
import { getVehicleWithdrawalReason } from '@/api/vehicle-withdrawal/getVehicleWithdrawalReason'
import { getVehicleWithdrawalDepot } from '@/api/vehicle-withdrawal/getVehicleWithdrawalDepot'
import { getVehicleComposition } from '@/api/vehicle-composition/getVehicleComposition'
import { endOfDay, format, startOfDay } from 'date-fns'
import { useRoute } from 'vue-router'
import { getFordonEnligtUtsattningsplan } from '@/api/custom/getFordonEnligtUtsattningsplan'

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
})

export type VehicleWithdrawalListVehicle = VehicleWithdrawal & {
  vehicleWithdrawalReason: string
  vehicleWithdrawalDepot: string
}

export interface VehicleWithdrawalList {
  label: string
  type: string
  value: {
    available: number
    withdrawn: number
    total: number
  }
  vehicles: VehicleWithdrawalListVehicle[]
}

export default defineComponent({
  name: 'VehicleReportPrint',

  components: {
    AppLogo,
    VehicleReportPrintAvailability,
    VehicleReportPrintCanceledTable,
  },

  setup() {
    const route = useRoute()
    const loading = ref(true)
    const fordonEnligtUtsattningsplan = ref({ count: 0, x15p: 0, x10p: 0 })
    const vehicleWithdrawalReason = ref<VehicleWithdrawalReason[]>([])
    const vehicleWithdrawalDepot = ref<VehicleWithdrawalDepot[]>([])
    const vehicleCompositions = ref<VehicleComposition[]>([])
    const { data: vehiclesData, fetchVehicles } = useVehicle()
    const { data: vehicleWithdrawals, fetchVehicleWithdrawals } =
      useVehicleWithdrawal()
    const dateNow = format(new Date(), 'yyyy-MM-dd')

    async function getData() {
      loading.value = true
      vehicleWithdrawalReason.value = await getVehicleWithdrawalReason()
      vehicleWithdrawalDepot.value = await getVehicleWithdrawalDepot()

      await fetchVehicles()
      await fetchVehicleWithdrawals()
      vehicleCompositions.value = await getVehicleComposition()

      fordonEnligtUtsattningsplan.value = await getFordonEnligtUtsattningsplan(
        dateNow
      )

      loading.value = false
      setTimeout(() => {
        document.body.classList.add('print-ready')
      }, 300)
      if (route.name === 'vehicle-report-pdf') return
      setTimeout(() => {
        window.print()
        window.close()
      }, 300)
    }

    getData()

    const list = computed(() => {
      const dateTime = +new Date()
      const validVehicles = vehiclesData.value.filter(
        (x) =>
          dateTime >= +startOfDay(new Date(x.start_date)) &&
          dateTime <= +endOfDay(new Date(x.end_date))
      )

      const total = {
        X10P: validVehicles.filter((x) => x.vehicle_type.name === 'X10P')
          .length,
        UBP: validVehicles.filter((x) => x.vehicle_type.name === 'UBP').length,
        UBXP: validVehicles.filter((x) => x.vehicle_type.name === 'UBXP')
          .length,
        X15p: validVehicles.filter((x) => x.vehicle_type.name === 'X15p')
          .length,
      }
      const withdrawn = {
        X10P: vehicleWithdrawals.value.filter(
          (x) => x.vehicle.vehicle_type.name === 'X10P'
        ).length,
        UBP: vehicleWithdrawals.value.filter(
          (x) => x.vehicle.vehicle_type.name === 'UBP'
        ).length,
        UBXP: vehicleWithdrawals.value.filter(
          (x) => x.vehicle.vehicle_type.name === 'UBXP'
        ).length,
        X15p: vehicleWithdrawals.value.filter(
          (x) => x.vehicle.vehicle_type.name === 'X15p'
        ).length,
      }

      const vehicles = vehicleWithdrawals.value.map((x) => {
        return {
          ...x,
          vehicleWithdrawalReason:
            vehicleWithdrawalReason.value.find(
              (y) => y.uuid === x.vehicle_withdrawal_reason_uuid
            )?.name || '',

          vehicleWithdrawalDepot:
            vehicleWithdrawalDepot.value.find(
              (y) => y.uuid === x.vehicle_withdrawal_depot_uuid
            )?.name || '',
        }
      })

      return [
        {
          label: 'Motorvagn X10P',
          type: 'X10P',
          value: {
            available: total.X10P - withdrawn.X10P,
            withdrawn: withdrawn.X10P,
            total: total.X10P,
          },
          vehicles: vehicles
            .filter((x) => x.vehicle.vehicle_type.name === 'X10P')
            .sort((a, b) => collator.compare(a.vehicle.name, b.vehicle.name)),
        },
        {
          label: 'Mellanvagn UBP',
          type: 'UBP',
          value: {
            available: total.UBP - withdrawn.UBP,
            withdrawn: withdrawn.UBP,
            total: total.UBP,
          },
          vehicles: vehicles
            .filter((x) => x.vehicle.vehicle_type.name === 'UBP')
            .sort((a, b) => collator.compare(a.vehicle.name, b.vehicle.name)),
        },
        {
          label: 'Manövervagn UBXP',
          type: 'UBXP',
          value: {
            available: total.UBXP - withdrawn.UBXP,
            withdrawn: withdrawn.UBXP,
            total: total.UBXP,
          },
          vehicles: vehicles
            .filter((x) => x.vehicle.vehicle_type.name === 'UBXP')
            .sort((a, b) => collator.compare(a.vehicle.name, b.vehicle.name)),
        },
        {
          label: 'X15p',
          type: 'X15p',
          value: {
            available: total.X15p - withdrawn.X15p,
            withdrawn: withdrawn.X15p,
            total: total.X15p,
          },
          vehicles: vehicles
            .filter((x) => x.vehicle.vehicle_type.name === 'X15p')
            .sort((a, b) => collator.compare(a.vehicle.name, b.vehicle.name)),
        },
      ]
    })

    const totalAvailableX10p = computed(() => {
      return vehicleCompositions.value
        .map((x) => {
          return x.vehicles.map(
            (vehicle_uuid) =>
              vehiclesData.value.find((vd) => vd.uuid === vehicle_uuid)?.name ||
              ''
          )
        })
        .filter(
          (vehicles) =>
            !vehicleWithdrawals.value.some((x) =>
              vehicles.includes(x.vehicle.name)
            ) &&
            vehicles.length === 3 &&
            vehicles[0][0] === '2' &&
            vehicles[1][0] === '8' &&
            vehicles[2][0] === '1'
        ).length

      // const available = [
      //   list.value.find((x) => x.type === 'X10P')?.value.available,
      //   list.value.find((x) => x.type === 'UBP')?.value.available,
      //   list.value.find((x) => x.type === 'UBXP')?.value.available,
      // ].filter((x): x is number => typeof x === 'number')

      // return Math.min(...available)
    })

    const totalAvailableX15p = computed(() => {
      const available = vehicleCompositions.value
        .map((x) => {
          return x.vehicles.map(
            (vehicle_uuid) =>
              vehiclesData.value.find((vd) => vd.uuid === vehicle_uuid)?.name ||
              ''
          )
        })
        .filter(
          (vehicles) =>
            !vehicleWithdrawals.value.some((x) =>
              vehicles.includes(x.vehicle.name)
            ) && vehicles[0][0] === '7'
        ).length

      return available
      // return Math.min(fordonEnligtUtsattningsplan.value.x15p, available)
    })

    return {
      vehicleWithdrawals,
      loading,
      list,
      totalAvailableX10p,
      totalAvailableX15p,
      dateNow,
      fordonEnligtUtsattningsplan,
    }
  },
})
