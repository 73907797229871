
import QTable from 'quasar/src/components/table/QTable.js';
import { defineComponent, computed, PropType } from 'vue'

export default defineComponent({
  name: 'VehicleReportPrintAvailability',

  props: {
    totalAvailableX10p: {
      type: Number,
      required: true,
    },
    totalAvailableX15p: {
      type: Number,
      required: true,
    },
    fordonEngligtUtsattningsplan: {
      type: Object as PropType<{ count: number; x10p: number; x15p: number }>,
      required: true,
    },
  },

  setup(props) {
    const columns: QTable['columns'] = [
      {
        align: 'left',
        name: 'text',
        label: 'Daglig sammanfattning',
        field: 'text',
        sortable: false,
        style: 'width: 230px; min-width: 230px',
      },
      {
        align: 'left',
        name: 'x10p',
        label: 'X10p',
        field: 'x10p',
        style: 'width: 100px;',
        sortable: false,
      },
      {
        align: 'left',
        name: 'x15p',
        label: 'X15p',
        field: 'x15p',
        style: 'width: 100px;',
        sortable: false,
      },
      {
        align: 'left',
        name: 'total',
        label: 'Total',
        field: 'total',
        style: 'width: 100px;',
        sortable: false,
      },
    ]

    const data = computed(() => {
      return [
        {
          text: 'Tågsätt enligt utsättningsplan',
          x10p: props.fordonEngligtUtsattningsplan.x10p,
          x15p: props.fordonEngligtUtsattningsplan.x15p,
          total: props.fordonEngligtUtsattningsplan.count,
        },
        {
          text: `Tågsätt tillgängliga 05:45`,
          x10p: props.totalAvailableX10p,
          x15p: props.totalAvailableX15p,
          total: props.totalAvailableX10p + props.totalAvailableX15p,
        },
      ]
    })

    console.log(data.value)

    return {
      columns,
      data,
    }
  },
})
